#root{min-width: 1280px;}

body *::-webkit-scrollbar-thumb{background-color: rgba(99, 99, 99, 0.5);border-radius: 5px;}

mark{color: var(--color-point01);}
hr{width: 100%; border: none; border-top: 1px solid var(--color-line); background-color: blue;}
hr.case01{margin-block: 15px 20px;}
hr.case02{margin-block: 20px 30px;}
hr.case03{margin-block: 20px 20px;}
label.required{display: flex;}
label.required::after{content: '*'; color: var(--color-point01); transform: translateY(2px);}

:where(.css-dev-only-do-not-override-dkbvqv).ant-picker .ant-picker-input:hover .ant-picker-clear{display: none;}


/* input 공통 */
input:not([type="reset"], [type="submit"]), textarea{width: 100%; height: 32px; padding-inline: 10px; font-weight: 400; border: 1px solid var(--color-line); border-radius: 4px; box-sizing: border-box;}
:is(input:not([type="reset"], [type="submit"]), textarea):focus{outline: 1px solid var(--color-point01);}
:is(input:not([type="reset"], [type="submit"]), textarea):disabled{background-color: var(--color-division);}
textarea{padding-block: 10px; height: 96px;}
input:not([type="submit"])::placeholder{color: var(--color-button);}
input[type="submit"]{border: none;}
input[readOnly]{color: var(--color-font02); background-color: var(--color-division);}
input[disabled]{background-color: white; color: inherit;}
:is(div, form):has( > [type="search"]){position: relative;}
:is(div, form):has( > [type="search"]) [type="search"]{padding-right: 30px;}
:is(div, form):has( > [type="search"]) [type="search"]:read-only{cursor: pointer;}
:is(div, form):has( > [type="search"]) button{position: absolute; right: 5px; top: 50%; translate: 0 -50%; width: 22px; aspect-ratio: 1; background: url(../images/icon-search.png) no-repeat center / cover; text-indent: -9999px;}
:is(div, form):has( > [type="search"]:read-only) button{pointer-events: none; cursor: pointer;}
/* 리셋 버튼 */
.resetArea:has([type="search"]){display: flex; align-items: center;}
.resetArea > button{flex-shrink: 0; width: 22px; aspect-ratio: 1; background: url(../images/icon-reset.png) no-repeat center / cover; text-indent: -9999px; margin-left: 5px;}
.ant-color-picker-trigger + input{padding-left: 35px;}
[data-unit]{position: relative;}
[data-unit]::after{content: attr(data-unit); position: absolute; right: 10px; top: 50%; translate: 0 -50%;}
[data-unit] input{padding-right: 25px;}
:where(.css-dev-only-do-not-override-dkbvqv).ant-picker .ant-picker-input >input{font-size: 13px; font-family: 'Pretendard';}


/* 버튼 */
[class*='btn']:not([class*="btnArea"]){padding-block: 8px; border-radius: 4px; border: none; white-space: nowrap; cursor: pointer;}
[class*='btn']:not([class*="btnArea"]):not(.btn-point){font-weight: 400;}
[class*='btn']:disabled{pointer-events: none;}
.btn-point{padding-inline: 30px; font-weight: 700; color: white; text-align: center; background-color: var(--color-point01);}
.btn-point:disabled{opacity: 0.3;}
.btn-gray-white{padding-inline: 30px; color: white; background-color: var(--color-button);}
.btn-gray-black{padding-inline: 15px; background-color: var(--color-division);}
.btn-gray-black:disabled{color: var(--color-button);}
.btn-gray-black.ant-btn{font-size: 13px; line-height: 16px; font-family: 'Pretendard'; color: var(--color-font01);}
.btn-gray-black.ant-btn-default:is(:hover, :active) span{color: var(--color-font01);}


/* 스크롤 */
.scroll-width{overflow: auto;}
.scroll-width::-webkit-scrollbar{width: 6px;}
.scroll-width::-webkit-scrollbar-thumb{background-color: rgba(99, 99, 99, 0.5);border-radius: 5px;}


/* 셀렉트 박스 */
[class*="selectBox"]{width: 100%; position: relative;}
[class*="selectBox"] button{height: 32px; text-align: left; width: 100%; padding-inline: 10px; border-radius: 4px; box-sizing: border-box;}
[class*="selectBox"] > button{font-weight: 400; border: 1px solid var(--color-line); position: relative;}
[class*="selectBox"] > button::before{content: ''; position: absolute; right: 10px; top: 50%; transform: translateY(-50%); width: 12px; aspect-ratio: 12 / 6; background: url(../images/icon-fill-arrow-gray.png) no-repeat center bottom / 100%; }
[class*="selectBox"] > button:disabled{background-color: var(--color-division);}
[class*="selectBox"] > button.gray{color: var(--color-button);}
[class*="selectBox"] div{position: absolute; left: 0; top: calc(100% + 3px); width: 100%; padding: 5px; background-color: white; border-radius: 4px; box-sizing: border-box; box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.15); z-index: 1;}
[class*="selectBox"] div button:hover{background-color: var(--color-point02); font-weight: 700;}
.selectBox-year{max-width: 90px;}
/* .selectBox-year button::after{content: '년';} */
.selectBox-month{max-width: 70px;}
/* .selectBox-month button::after{content: '월';} */
.board-pagination:has(.selectBox-pagerCount){position: relative;}
[class*="selectBox"].selectBox-pagerCount div{bottom: calc(100% + 3px); top: auto;}
.selectBox-pagerCount{max-width: 80px; position: absolute; left: 55px; top: 0;}
.selectBox-pagerCount::before{content: '노출개수'; position: absolute; left: 0; top: 50%; translate: calc(-100% - 10px) -50%;}
.selectBox-excelCount{max-width: 130px;}
[class*='selectBox-time']{width: 80px;}
[class*='selectBox-time'] div{max-height: 250px; overflow: auto;}
[class*='selectBox-time'] div::-webkit-scrollbar{width: 6px;}
[class*='selectBox-time'] div::-webkit-scrollbar-thumb{background-color: rgba(99, 99, 99, 0.5);border-radius: 5px;}
[class*="selectBoard"]{width: 100%; position: relative; padding-right: 10px;}
[class*="selectBoard"] > button{height: 32px; text-align: left; width: 100%; padding-inline: 10px; border: 1px solid var(--color-line); border-radius: 4px; box-sizing: border-box;}
[class*="selectBoard"] > button{ position: relative;}
[class*="selectBoard"] > button::before{content: ''; position: absolute; right: 10px; top: 50%; transform: translateY(-50%); width: 12px; aspect-ratio: 12 / 6; background: url(../images/icon-fill-arrow-gray.png) no-repeat center bottom / 100%; }
[class*="selectBoard"] div{position: absolute; left: 0; top: calc(100% + 3px); width: 100%; max-height: 300px; overflow: auto; padding: 5px; background-color: white; border-radius: 4px; box-sizing: border-box; box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.15); z-index: 1;}
[class*="selectBoard"] div button{width: 100%; height: 32px; font-weight: 400;}
[class*="selectBoard"] div button:hover{background-color: var(--color-point02); font-weight: 700;}


/* 팝업 */
body:has(.popupBox){overflow: hidden;}
.popupBox{position: fixed; left: 0; top: 0; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; background-color: rgba(0, 0, 0, 0.5); z-index: 101;}
.popupBox > div{padding: 20px; box-sizing: border-box; background-color: white; box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.15); border-radius: 4px; position: relative;}
.popupBox > div > button{position: absolute; right: 20px; top: 20px; width: 18px; aspect-ratio: 1; background: url(../images/icon-close.png) no-repeat center / cover; text-indent: -9999px;}
.popupBox > div:is(.confirmArea, .finFuncArea){width: 320px; max-width: 320px;}
.popupBox > div:has([class*="bureau"]){width: 340px;}
.popupBox > div:has(.refundPopup, .updatePopup){width: 900px;}
.popupBox > div[class*="newPassword"]{width: 340px;}
.popupBox > div[class*="properties"]{width: 340px;}
.popupBox > div:is(.analystArea, .salesArea, .salesArrayArea, .salesArrayAddArea){width: 510px;}
.popupBox > div .rolePopup{width: 510px;}
.popupBox > div strong{display: block; font-size: 15px; line-height: 18px; font-weight: 700; text-align: center; margin-bottom: 30px;}
.popupBox > div p{text-align: center; white-space: pre-line;}
.popupBox > div b{font-weight: 700;}
.popupBox > div b + form{margin-top: 10px;}
.popupBox > div [class*="btnArea"]{display: flex; gap: 10px; margin-top: 30px;}
.popupBox > div p + .btnArea{justify-content: center;}
.popupBox > div .btnArea-end{justify-content: flex-end;}
.popupBox [class*="choice"]{width: 100%; height: 96px; background-color: var(--color-face); padding: 12px 20px; box-sizing: border-box; display: flex; gap: 12px 20px;}
li.icon-remove{display: flex; align-items: center;}
li.icon-remove button{width: 16px; aspect-ratio: 1; background: url(../images/icon-delete.png) no-repeat center / cover; text-indent: -9999px; margin-left: 6px;}
.popupBox .choice-horizontal{flex-wrap: wrap; align-content: start;}
.popupBox .choice-vertical{flex-direction: column;}
.popupBox .newPasswordArea fieldset ul{margin-top: 20px;}
.popupBox .newPasswordArea fieldset ul li div{margin-top: 10px;}
.popupBox .newPasswordArea fieldset ul li:not(:last-child){margin-bottom: 20px;}
.popupBox .bureauArea .searchArea{margin-bottom: 12px;}
.popupBox .bureauArea .bureauBox .listArea > ul{height: 353px;}
.popupBox :is(.analystArea, .salesArea, .salesArrayArea, .salesArrayAddArea) .searchArea{max-width: 300px; margin-inline: auto; margin-bottom: 20px;}
.popupBox :is(.analystArea, .salesArea, .salesArrayArea, .salesArrayAddArea) .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(1){flex: 100;}
.popupBox :is(.analystArea, .salesArea, .salesArrayArea, .salesArrayAddArea) .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(2){flex: 80;}
.popupBox :is(.analystArea, .salesArea, .salesArrayArea, .salesArrayAddArea) .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(3){flex: 90;}
/* .popupBox :is(.analystArea, .salesArea, .salesArrayArea) .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(4){flex: 70;} */
.popupBox :is(.analystArea, .salesArea, .salesArrayArea, .salesArrayAddArea) .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(4){flex: 30;}
.popupBox :is(.salesArrayArea, .salesArrayAddArea) [class*="choice"]{margin-top: 20px;}
.popupBox :is(.refundPopup, .updatePopup) > b{display: block; margin-bottom: 12px;}
.popupBox :is(.refundPopup, .updatePopup) table{width: 100%; margin-bottom: 30px; border-width: 1px 0; border-style: solid; border-color: var(--color-division);}
.popupBox :is(.refundPopup, .updatePopup) table :is(th, b, mark){font-weight: 700;}
.popupBox :is(.refundPopup, .updatePopup) table tr{height: 40px; display: flex;}
.popupBox :is(.refundPopup, .updatePopup) table tr > *{flex: 1;}
.popupBox :is(.refundPopup, .updatePopup) table tr > *:not(:last-child){border-right: 1px solid var(--color-division);}
.popupBox :is(.refundPopup, .updatePopup) table tr th{line-height: 40px; background-color: var(--color-face);}
.popupBox :is(.refundPopup, .updatePopup) table tr td{display: flex; justify-content: center; align-items: center;}
.popupBox :is(.refundPopup, .updatePopup) table > *:not(:last-child){border-bottom: 1px solid var(--color-division);}
.popupBox :is(.refundPopup, .updatePopup) table thead tr{background-color: var(--color-face);}
.popupBox :is(.refundPopup, .updatePopup) table tbody tr td{text-align: center;}
.popupBox :is(.refundPopup, .updatePopup) fieldset:not(:last-of-type){padding-bottom: 30px; border-bottom: 1px solid var(--color-division);}
.popupBox :is(.refundPopup, .updatePopup) fieldset:not(:last-of-type):has( + :not([class])){margin-bottom: 30px;}
.popupBox :is(.refundPopup, .updatePopup) fieldset b{display: block; margin-bottom: 20px;}
.popupBox :is(.refundPopup, .updatePopup) fieldset ul{display: flex; gap: 0 40px;}
.popupBox :is(.refundPopup, .updatePopup) fieldset ul:not(:last-of-type){margin-bottom: 30px;}
.popupBox :is(.refundPopup, .updatePopup) fieldset ul.settingArea{padding: 20px; flex-wrap: wrap; background-color: var(--color-division);}
.popupBox :is(.refundPopup, .updatePopup) fieldset ul.settingArea::before{content: attr(data-subText); width: 100%; font-weight: 700; color: var(--color-point01); margin-bottom: 20px;}
.popupBox :is(.refundPopup, .updatePopup) fieldset ul li{flex: 1;}
.popupBox :is(.refundPopup, .updatePopup) fieldset ul li > label:first-child{font-weight: 700;}
.popupBox :is(.refundPopup, .updatePopup) fieldset ul li > label:first-child span{font-size: 12px; font-weight: 400; color: var(--color-font03); margin-left: 0.4em;}
.popupBox :is(.refundPopup, .updatePopup) fieldset ul li > label:first-child span::before{content: '(';}
.popupBox :is(.refundPopup, .updatePopup) fieldset ul li > label:first-child span::after{content: ')';}
.popupBox :is(.refundPopup, .updatePopup) fieldset ul li > div{margin-top: 10px;}
.popupBox :is(.refundPopup, .updatePopup) fieldset ul li > div:has(:is([type="radio"], [type="checkbox"])){margin-top: 18px;}
.popupBox :is(.refundPopup, .updatePopup) fieldset > ul li > div:has([type="radio"]){display: flex; flex-wrap: wrap; gap: 15px 20px;}
.popupBox :is(.refundPopup, .updatePopup) fieldset > ul li > div > div{width: 100%; display: flex; align-items: center;}
.popupBox :is(.refundPopup, .updatePopup) fieldset > ul li > div > div span{margin-inline: 5px;}
.popupBox :is(.refundPopup, .updatePopup) fieldset > ul li > div > div .ant-picker-suffix{width: 20px; margin: 0; color: black;}
.popupBox :is(.refundPopup, .updatePopup) fieldset > ul li > div [class*="selectBox"] > button{background-color: white;}
.popupBox .excelDownloadArea{width: 510px;}
.popupBox .excelDownloadArea .excelArea{display: flex; flex-wrap: wrap; align-items: center; gap: 12px 10px; margin-top: 20px;}
.popupBox .excelDownloadArea .excelArea ol{width: 100%; max-height: 400px; padding-block: 15px; border-width: 1px 0; border-style: solid; border-color: #DEDEDE; display: flex; flex-wrap: wrap; gap: 10px; box-sizing: border-box;}
.popupBox .excelDownloadArea .excelArea ol li{width: calc((100% - 20px) / 3);}
.popupBox .excelDownloadArea .excelArea ol li button{width: 100%; font-weight: 700; line-height: 40px; background-color: #F1F1F1; border-radius: 4px;}
.popupBox .payHistoryArea{width: 900px;}


/* 부서 리스트 */
.bureauBox{width: 300px; padding-top: 20px; border: 1px solid var(--color-line); box-sizing: border-box;}
/* .bureauBox:not(:has( + .addBtn)){padding-bottom: 12.5px;} */
.bureauBox .listArea{display: flex; flex-direction: column;}
.bureauBox .listArea > *:first-child{font-size: 15px; line-height: 18px; padding-inline: 20px; font-weight: 700; text-align: left; margin-bottom: 5px;}
.bureauBox .listArea > ul{padding-inline: 20px; padding-block: 2.5px; box-sizing: border-box; overflow: auto;}
.bureauBox .listArea > ul::-webkit-scrollbar{width: 6px;}
.bureauBox .listArea > ul::-webkit-scrollbar-thumb{background-color: rgba(99, 99, 99, 0.5);border-radius: 5px;}
.bureauBox .listArea > ul li{position: relative; padding-inline: 20px;}
.bureauBox .listArea > ul li > button:first-child{padding-block: 7.5px; width: 100%; text-align: left;}
/* .bureauBox .listArea > ul li > button:first-child{padding-inline: 20px;} */
.bureauBox .listArea > ul li > button:first-child.active{color: var(--color-point01);}
.bureauBox .listArea > ul li > button:nth-of-type(2){position: absolute; left: 0; top: 0; width: 8px; aspect-ratio: 8 / 11; padding: 10px 20px 10px 0; background: url(../images/icon-fill-arrow-right-black.png) no-repeat left center / 8px; font-size: 5px; text-indent: -9999px;}
.bureauBox .listArea :is(button, summary).active{color: var(--color-point01); font-weight: 700; display: flex; align-items: center;}
.bureauBox .listArea :is(button, summary).active::after{content: ''; width: 16px; aspect-ratio: 1; background: url(../images/icon-check.png) no-repeat center / cover; margin-left: 6px;}
.bureauBox .listArea > ul li details summary{padding-block: 7.5px; padding-inline: 20px; cursor: pointer; list-style-type: none;}
.bureauBox .listArea > ul li details summary::before{content: ''; width: 8px; aspect-ratio: 8 / 6; background: url(../images/icon-fill-arrow-black.png) no-repeat center / cover; position: absolute; left: 4px; top: 50%; translate: 0 -50%; rotate: -90deg;}
.bureauBox .listArea > ul li details :is(summary, button){position: relative;}
.bureauBox .listArea > ul li details :is(summary, button)::before{content: ''; width: 8px; aspect-ratio: 8 / 6; background: url(../images/icon-fill-arrow-black.png) no-repeat center / cover; position: absolute; top: 50%; translate: 0 -50%; rotate: -90deg;}
.bureauBox .listArea > ul li details summary::before{left: 4px;}
.bureauBox .listArea > ul li details button::before{left: 26px;}
.bureauBox .listArea > ul li details[open] summary::before{rotate: 0deg;}
.bureauBox .listArea > ul li details button{padding-inline: 40px;}
.bureauBox .addBtn{margin-inline: 20px;}


/* 게시판 */
/* [class*="Page"] > .boardBox{margin-top: 60px;} */
[class*="Page"] > .dropBox + .boardBox{margin-top: 60px;}
.boardBox > strong{margin-right: auto;}
.boardBox{display: flex; align-items: center; flex-wrap: wrap;}
.boardBox > [class*="selectBox"] + [class*="selectBox"]{margin-left: 10px;}
.boardBox [class*="board-scroll"]{/* margin-top: 15px; */ overflow: auto;}
.boardBox [class*="board-scroll"]::-webkit-scrollbar{height: 6px;}
.boardBox [class*="board-scroll"]::-webkit-scrollbar-thumb{background-color: rgba(99, 99, 99, 0.5);border-radius: 5px;}
.boardBox :is(.board-top, .board-center, .board-bottom){min-width: 100%;}
/* .boardBox div:has(:is(.board-top, .board-center, .board-bottom)){width: 100%;} */
.boardBox :has(:is(.board-top, .board-center, .board-bottom)){width: 100%;}
.boardBox :is(.board-top, .board-center li, .board-bottom){min-width: 100%; display: inline-flex; align-items: center; gap: 10px; height: 56px; padding-inline: 10px; box-sizing: border-box;}
.boardBox :is(.board-top, .board-center li, .board-bottom) > div{display: flex; column-gap: 10px;}
.boardBox :is(.board-top, .board-center li, .board-bottom) > div > *{flex: 1;}
.boardBox :is(.board-top, .board-center li, .board-bottom) > div[title]{flex-wrap: wrap;}
.boardBox :is(.board-top, .board-center li, .board-bottom) > div[title]::before{content: attr(title); width: 100%; font-size: 12px; line-height: 14px; color: var(--color-font03); text-align: center; margin-bottom: 6px;}
.boardBox :is(.board-top, .board-bottom){background-color: var(--color-face);}
.boardBox :is(.board-top, .board-bottom) > *{font-weight: 700;}
.boardBox .board-top{margin-top: 15px;}
.boardBox .board-top > *:not(:last-child){display: flex; justify-content: space-between; align-items: center; position: relative;}
.boardBox .board-top > *:not(:last-child)::after{content: ''; width: 1px; height: 16px; background-color: var(--color-line); position: absolute; right: 0; top: 50%; translate: 0 -50%;}
.boardBox .board-top:has([title]) > *:not(:last-child)::after{height: 32px;}
.boardBox .board-top > button{text-align: left; position: relative;}
.boardBox .board-top > button::before{content: ''; width: 10px; aspect-ratio: 10 / 18; background: url(../images/icon-sort.png) no-repeat center / cover; position: absolute; right: 10px; top: 50%; translate: 0 -50%;}
.boardBox .board-center li{border-bottom: 1px solid var(--color-division); white-space: pre-line}
.boardBox .board-center li.active{background-color: #FFF9F9;}
.boardBox .board-center li > *{text-align: left; font-weight: 400;}
.boardBox .board-center li button.select{display: flex; align-items: center;}
.boardBox .board-center li button.select::after{content: ''; width: 12px; aspect-ratio: 12 / 6; background: url(../images/icon-fill-arrow-gray.png) no-repeat center top / 100%; margin-left: 6px;}
.boardBox .board-center li :is(a, .popup, .board-center button.point){color: var(--color-point01); text-decoration: underline; text-underline-offset: 2px;}
.boardBox .board-center li .point{color: var(--color-point01);}
.boardBox .board-center li div:has(.popup){display: flex; gap: 10px;}
.boardBox .board-center li > div[style]{display: flex; align-items: center; padding-left: 4px; position: relative;}
.boardBox .board-center li > div[style]::before{content: ''; width: 18px; aspect-ratio: 1; background-color: var(--color); border-radius: 4px; box-sizing: border-box;}
.boardBox .board-center li > div[style]::after{content: ''; position: absolute; left: 0; top: 50%; transform: translateY(-50%); width: 24px; aspect-ratio: 1; border: 1px solid var(--color-line); border-radius: 4px;}
.boardBox .board-center li div .ant-color-picker-trigger{border: none; justify-content: flex-start;}
.boardBox .board-center li div .ant-color-picker-trigger .ant-color-picker-color-block{padding: 2px; border: 1px solid var(--color-line); background-image: none;}
.boardBox .board-center li div .ant-color-picker-trigger .ant-color-picker-trigger-text{margin-inline-start: 6px;}
.boardBox .board-center li:has( > *:first-child input[type="checkbox"]:checked){background-color: #FFF9F9;}
.boardBox .board-pagination{width: 100%; display: flex; justify-content: center; gap: 10px; margin-top: 20px;}
/* .boardBox .board-pagination > a{width: 24px; aspect-ratio: 24 / 32; background: url(../images/icon-pagination.png) no-repeat center top calc(100% / (var(--styleTotal) - 1) * var(--styleIdx)) / 100%; text-indent: -9999px;} */
.boardBox .board-pagination > :is(a, button){width: 24px; aspect-ratio: 24 / 32; background: url(../images/icon-pagination.png) no-repeat center top calc(100% / 3 * var(--styleIdx)) / 100%; text-indent: -9999px;}
.boardBox .board-pagination > :is(a, button):nth-of-type(1){--styleIdx: 0;}
.boardBox .board-pagination > :is(a, button):nth-of-type(2){--styleIdx: 1;}
.boardBox .board-pagination > :is(a, button):nth-of-type(3){--styleIdx: 2;}
.boardBox .board-pagination > :is(a, button):nth-of-type(4){--styleIdx: 3;}
.boardBox .board-pagination ol{display: flex;}
.boardBox .board-pagination ol li{display: flex;}
.boardBox .board-pagination ol li :is(a, button){display: flex; align-items: center; font-weight: 700; color: var(--color-button); padding-inline: 10px;}
.boardBox .board-pagination ol li.active :is(a, button){color: var(--color-point01); text-decoration: underline; text-underline-offset: 2px;}
/* .boardBox :is(.board-top *, .board-center li > *, .board-bottom *){} */
.boardBox .btn-gray-black.boundary{margin-left: 30px; position: relative;}
.boardBox .btn-gray-black.boundary::after{content: ''; width: 1px; height: 50%; background-color: var(--color-line); position: absolute; left: -15px; top: 50%; translate: 0 -50%;}
.boardBox .total{font-size: 15px; font-weight: 700;}
.boardBox .total::before{content: '총';}
.boardBox .total::after{content: '건';}
.boardBox .page{font-weight: 400; color: var(--color-font03); margin-left: 0.4em;}
.boardBox .page::before{content: '(';}
.boardBox .page::after{content: 'page)';}
.boardBox .choice{font-weight: 700; margin-right: 10px;}
.boardBox .choice::before{content: '선택 ';}
.boardBox .choice::after{content: '건';}
.boardBox .page:has(+ .choice){margin-right: auto;}
.boardBox .board-table{width: 100%; margin-bottom: 30px; border-width: 1px 0; border-style: solid; border-color: var(--color-division);}
.boardBox .board-table :is(th, b, mark){font-weight: 700;}
.boardBox .board-table tr{height: 40px;}
.boardBox .board-table tr > *{line-height: 40px;}
.boardBox .board-table tr > *:not(:last-child){border-right: 1px solid var(--color-division);}
.boardBox .board-table > *:not(:last-child){border-bottom: 1px solid var(--color-division);}
.boardBox .board-table thead tr{background-color: var(--color-face);}
.boardBox .board-table tbody tr td{text-align: center;}
.boardBox .btn-gray-white:last-child{margin-left: auto; margin-top: 20px;}


/* 탭 */
.tabBox{display: flex; gap: 24px;}
.tabBox button{font-size: 20px; line-height: 24px; font-weight: 700; color: var(--color-button);}
.tabBox button.active{color: var(--color-font01);}
.tabBox button:not(:first-child){position: relative;}
.tabBox button:not(:first-child)::before{content: ''; position: absolute; left: -12px; top: 50%; translate: 0 -50%; width: 1px; height: 16px; background-color: var(--color-line);}


/* 검색 */
[type="checkbox"] + label{display: flex; align-items: flex-start; cursor: pointer;}
[type="checkbox"] + label::before{content: ''; flex-shrink: 0; --styleIdx: 0; width: 16px; aspect-ratio: 1; background: url(../images/icon-checkbox.png) no-repeat center top calc(100% * var(--styleIdx)) / 100%; margin-right: 6px;}
[type="checkbox"]:checked + label::before{--styleIdx: 1;}
[type="radio"] + label{white-space: nowrap; display: flex; align-items: center; cursor: pointer;}
[type="radio"] + label::before{content: ''; --styleIdx: 0; width: 16px; aspect-ratio: 1; background: url(../images/icon-radio.png) no-repeat center top calc(100% * var(--styleIdx)) / 100%;}
[type="radio"] + label:not(:has( + label)):before{margin-right: 6px;}
[type="radio"]:checked + label::before{--styleIdx: 1;}
.dropBox{--padding: 20px; border: 1px solid var(--color-line);}
.dropBox > :is(button, b){padding: var(--padding); font-size: 15px; line-height: 18px; font-weight: 700; text-align: left;}
.dropBox > button{width: 100%;}
.dropBox > b{display: block;}
.dropBox > button.arrow{position: relative;}
.dropBox > button.arrow::after{content: ''; position: absolute; right: var(--padding); top: 50%; translate: 0 -50%; width: 26px; aspect-ratio: 1; background: url(../images/icon-arrow.png) no-repeat center / cover;}
.dropBox > button.arrow.active::after{rotate: 180deg;}
.dropBox > :is(button, b) + *{margin-top: 10px; padding: 0 var(--padding) var(--padding);}
.dropBox form fieldset:not(:last-of-type){padding-bottom: 30px; border-bottom: 1px solid var(--color-division);}
.dropBox form fieldset:not(:last-of-type):has( + [class]){margin-bottom: 20px;}
.dropBox form fieldset:not(:last-of-type):has( + :not([class])){margin-bottom: 30px;}
.dropBox form fieldset:not(:has(input[type="checkbox"])){padding-right: 100px;}
.dropBox form fieldset[class] > b{display: block; font-size: 15px; line-height: 18px; font-weight: 700; margin-bottom: 30px;}
.dropBox form fieldset[class] > label{font-weight: 700;}
.dropBox form fieldset > ul{display: flex; flex-wrap: wrap; gap: 30px 40px;}
.dropBox form fieldset > ul li:not([class]){width: calc((100% - 80px) / 3);}
.dropBox form fieldset > ul li.fill-two{width: calc((100% - 80px) / 3 * 2 + 40px);}
.dropBox form fieldset > ul li.fill-three{width: 100%;}
.dropBox form fieldset > ul li > label:first-child{font-weight: 700;}
:where(.dropBox form fieldset > ul li > label:first-child){display: block;}
.dropBox form fieldset > ul li > label:first-child:has( + div :is([type="radio"], [type="checkbox"])){margin-bottom: 18px;}
.dropBox form fieldset > ul li > label:first-child:not(:has( + div :is([type="radio"], [type="checkbox"]))){margin-bottom: 10px;}
.dropBox form fieldset > ul li > label:first-child span{font-size: 12px; font-weight: 400; color: var(--color-font03); margin-left: 0.4em;}
.dropBox form fieldset > ul li > label:first-child span::before{content: '(';}
.dropBox form fieldset > ul li > label:first-child span::after{content: ')';}
.dropBox form fieldset > ul li > div:has([type="checkbox"]){display: flex; flex-wrap: wrap; gap: 15px 30px;}
.dropBox form fieldset > ul li > div:has([type="checkbox"]) [type="checkbox"] + label{width: 145px; /* flex: 1;  */ white-space: nowrap;}
.dropBox form fieldset > ul li > div:has([type="checkbox"]) > span{display: flex;}
.dropBox form fieldset > ul li > div:has([type="checkbox"]) > span:has(span){display: flex;}
.dropBox form fieldset > ul li > div:has([type="radio"]){display: flex; flex-wrap: wrap; gap: 15px 20px;}
.dropBox form fieldset:has([type="radio"]):has(.ant-picker) > ul li > div:not(:has([type="radio"])) > div{padding-top: 39px;}
.dropBox form fieldset > ul li > div:has(.btn-gray-black, [class*="selectBox"]){display: flex; gap: 10px;}
.dropBox form fieldset > ul li > div > div{width: 100%; display: flex; align-items: center;}
.dropBox form fieldset > ul li > div > div span{margin-inline: 5px;}
.dropBox form fieldset > ul li > div > div .ant-picker-suffix{width: 20px; margin: 0; color: black;}
.dropBox form fieldset > ul li > div:has(.ant-color-picker-trigger){position: relative;}
.dropBox form fieldset > ul li > div .ant-color-picker-trigger{position: absolute; left: 7px; top: 50%; translate: 0 -50%; width: auto; background-color: transparent; min-width: auto; height: auto; padding: 0;}
.dropBox form fieldset > ul li > div .ant-color-picker-trigger .ant-color-picker-color-block{width: 18px; height: 18px;}
.dropBox form > div{display: flex; justify-content: flex-end; gap: 10px; margin-top: 60px;}
.dropBox form > div .btn-point:first-child:not(:only-child){margin-right: auto;}


/* 가로 2단 배치 */
.horizontalTwo{display: flex; align-items: flex-start; gap: 40px;}
.horizontalTwo > div:first-child{flex-shrink: 0;}
.horizontalTwo > :nth-child(2){flex: 1;}
/* 역할명 */
.horizontalTwo .roleName{width: 200px; border: 1px solid var(--color-line);}
.horizontalTwo .roleName > b{display: block; font-weight: 700; text-align: center; padding-block: 15px; border-bottom: 1px solid var(--color-line);}
.horizontalTwo .roleName > ul{height: 414px; overflow: auto;}
.horizontalTwo .roleName > ul::-webkit-scrollbar{width: 6px;}
.horizontalTwo .roleName > ul li:first-child{padding-top: 7.5px;}
.horizontalTwo .roleName > ul li:last-child{padding-bottom: 7.5px;}
.horizontalTwo .roleName > ul li button{width: 100%; padding-inline: 20px; text-align: left; line-height: 31px;}
.horizontalTwo .roleName > ul li button.active{color: var(--color-point01); font-weight: 700; display: flex; align-items: center;}
.horizontalTwo .roleName > ul li button.active::after{content: ''; width: 16px; aspect-ratio: 1; background: url(../images/icon-check.png) no-repeat center / cover; margin-left: 6px;}




/* 서브 페이지 */
.subPage{display: flex; --navWidth: 200px;}
.subPage nav{width: var(--navWidth); flex-shrink: 0; min-height: calc(100vh - var(--headerHeight)); padding: 25px 20px; box-sizing: border-box; background-color: #333;}
.subPage nav :is(strong, b){color: white; font-weight: 700; display: block; margin-bottom: 15px;}
.subPage nav strong{font-size: 15px; line-height: 18px;}
.subPage nav strong:not(:first-of-type){padding-top: 25px; margin-top: 25px; border-top: 1px solid rgba(255, 255, 255, 0.1);}
.subPage nav ul li:not(:last-child){margin-bottom: 15px;}
.subPage nav ul li a{display: block; font-weight: 400; color: rgba(255, 255, 255, 0.7);}
.subPage nav ul li a.active{color: var(--color-point01);}
.subPage nav b + ul{margin-bottom: 30px;}
.subPage nav b + ul li a{padding-inline: 10px;}
.subPage nav p{font-size: 11px; line-height: 13px; color: rgba(255, 255, 255, 0.5); font-weight: 400; text-align: center; margin-top: 40px;}
.subPage > [class*="Page"]{width: calc(100% - var(--navWidth)); padding: 30px 40px 60px; box-sizing: border-box;}
.subPage > [class*="Page"] > [class*="Page"] > div:not(:last-of-type){margin-bottom: 60px;}
.subPage h2{font-size: 27px; line-height: 32px; margin-bottom: 40px; font-weight: 700;}
.subPage h2:has(.btn-point, input[type="search"]){display: flex; align-items: center; justify-content: space-between;}
.subPage h2 .btn-point{font-size: 13px; line-height: 16px;}
.subPage h2:has(input[type="search"]) > :is(div, form){width: 260px; line-height: 16px;}
.subPage h2:has(input[type="search"]) > :is(div, form) input[type="search"]{font-size: 13px;}
.subPage strong{font-size: 20px; line-height: 24px; font-weight: 700;}


/* 대량 고객 */
ol.bulkBox > li{--numbWidth: 32px; padding-left: calc(var(--numbWidth) + 15px); padding-bottom: 35px; margin-bottom: 5px; counter-increment: customerModify; position: relative;}
ol.bulkBox > li::before{content: counter(customerModify); position: absolute; left: 0; top: 0; width: var(--numbWidth); aspect-ratio: 1; font-size: 15px; color: var(--color-point01); font-weight: 700; display: flex; justify-content: center; align-items: center; background-color: var(--color-point02); border-radius: 100%; border-width: 0 0 5px 0; border-style: solid; border-color: white; z-index: 1;}
ol.bulkBox > li:not(:last-child):after{content: ''; width: 1px; height: 100%; position: absolute; left: calc(var(--numbWidth) / 2); bottom: 0; background-color: var(--color-point01);}
ol.bulkBox > li > b{display: block; font-size: 15px; line-height: var(--numbWidth); font-weight: 700; margin-bottom: 8px;}
ol.bulkBox > li > div{padding-inline: 30px; border: 1px dashed var(--color-line); border-radius: 10px;}
ol.bulkBox > li:nth-child(-n + 2) > div{padding-block: 30px;}
ol.bulkBox > li:nth-child(3) > div{padding-block: 60px;}
ol.bulkBox > li:not(:nth-child(3)) > div{display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between; column-gap: 10px;}
ol.bulkBox > li:not(:nth-child(3)) > div :is(p, b, ol){line-height: 20px; color: var(--color-font02);}
ol.bulkBox > li:not(:nth-child(3)) > div :is(p, b, ol)[title]::before{content: attr(title); font-weight: 700; display: block;}
ol.bulkBox > li:nth-child(1) > div .btn-point[title]{display: flex; flex-direction: column; align-items: center;}
ol.bulkBox > li:nth-child(1) > div .btn-point[title]::after{content: '(' attr(title) ')'; width: 100%; font-weight: 400;}
ol.bulkBox > li:nth-child(1) > div .btn-point:has(+ .btn-point){margin-left: auto;}
ol.bulkBox > li:nth-child(2) > div > *:nth-child(n + 3){width: 100%; margin-top: 20px;}
ol.bulkBox > li:nth-child(2) > div div:has(input){display: flex; gap: 7px;}
ol.bulkBox > li:nth-child(2) > div div:has(input) input{width: 400px; height: 32px;}
ol.bulkBox > li:nth-child(3) > div{text-align: center;}
ol.bulkBox > li:nth-child(3) > div p{font-size: 20px; line-height: 24px; font-weight: 400;}
ol.bulkBox > li:nth-child(3) > div > mark{display: block; font-weight: 700; margin-top: 10px;}
ol.bulkBox > li:nth-child(3) > div > mark::before{content: '('; color: var(--color-font03);}
ol.bulkBox > li:nth-child(3) > div > mark::after{content: '/' attr(data-total) ')'; color: var(--color-font03);}
ol.bulkBox > li:nth-child(3) > div dl{display: flex; justify-content: center; align-items: center;}
ol.bulkBox > li:nth-child(3) > div dl dt{display: flex; align-items: center;}
ol.bulkBox > li:nth-child(3) > div dl dt::after{content: ':'; margin-inline: 0.4em;}
ol.bulkBox > li:nth-child(3) > div p + dl{font-weight: 400; color: var(--color-font02);}
ol.bulkBox > li:nth-child(3) > div mark + dl{font-weight: 700; margin-top: 30px;}
ol.bulkBox > li:nth-child(3) > div ul{display: flex; flex-wrap: wrap; justify-content: center; color: var(--color-font02); margin-top: 7px;}
ol.bulkBox > li:nth-child(3) > div ul li:not(:first-child):before{content: ', ';}
.customerPage.modifyPage ol.bulkBox > li:nth-child(2) > div{padding-block: 60px;}
/* 나중에 삭제 */
.customerPage.modifyPage ol > li:nth-child(3) > div:not(:last-child){margin-bottom: 20px;}


/* 해더 */
header{position: sticky; top: 0; left: 0; width: 100%; height: var(--headerHeight); background-color: white; display: flex; align-items: center; padding-inline: 15px 20px; box-sizing: border-box; box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10); z-index: 100;}
header h1{font-size: 15px; font-weight: 700; display: flex; align-items: center;}
header h1 a{max-width: 154px; margin-right: 30px;}
header h1 a img{width: 100%;}
header strong{margin-left: auto; font-weight: 700; margin-right: 30px;}
header > :is(a, button){font-size: 12px; font-weight: 400; color: var(--color-font03); height: 100%; display: flex; align-items: center;}
header > button:first-of-type{padding-right: 15px;}
header > button:not(:first-of-type){padding-left: 15px; position: relative;}
header > button:not(:first-of-type)::before{content: ''; position: absolute; left: 0; top: 50%; transform: translateY(-50%); width: 1px; height: 12px; background-color: var(--color-font03);}