:root{
    --color-font01: #222;
    --color-font02: #666;
    --color-font03: #999;
    --color-button: #BBB;
    --color-line: #DEDEDE;
    --color-division: #F1F1F1;
    --color-face: #F8F8F8;
    --color-point01: #E02C28;
    --color-point02: #FFEDEC;
    --color-point03: #FFF9F9;
    --headerHeight: 60px;
}