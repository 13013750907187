/* 로그인 페이지 */
.signInPage{height: 100vh; display: flex; flex-direction: column; justify-content: center; align-items: center;}
.signInPage h1{max-width: 164px; margin-bottom: 40px;}
.signInPage h1 img{width: 100%;}
.signInPage fieldset{width: 340px; padding: 80px 40px; background-color: white; box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.10); box-sizing: border-box;}
.signInPage fieldset h2{font-size: 20px; line-height: 24px; font-weight: 700; text-align: center; margin-bottom: 30px;}
.signInPage fieldset ul{margin-bottom: 30px;}
.signInPage fieldset ul li:not(:last-child){margin-bottom: 10px;}
.signInPage fieldset input[type="submit"]{width: 100%; height: 40px; color: white; background-color: var(--color-point01);}
.signInPage p{max-width: 100%; font-size: 12px; line-height: 14px; font-weight: 400; color: var(--color-button); margin-top: 20px;}


/* 메인 페이지 */
.mainPage > a{min-width: 100px; height: 40px; display: flex; align-items: center; padding-inline: 20px; background-color: var(--color-division); border-radius: 4px; margin-bottom: 60px;}
.mainPage > a::before{content: attr(title); font-weight: 700; margin-right: 40px;}
.mainPage > a::after{content: ''; order: -1; width: 16px; aspect-ratio: 1; background: url(../images/icon-notice.png) no-repeat center / cover; margin-right: 10px;}
.mainPage > a time{font-size: 12px; font-weight: 400; color: var(--color-font03); margin-right: 10px;}
.mainPage > a p{font-weight: 400; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.mainPage > a[data-new] p{padding-right: 15px; position: relative;}
.mainPage > a[data-new] p::after{content: ''; position: absolute; right: 0; top: 0; width: 7px; aspect-ratio: 1; background: url(../images/icon-new.png) no-repeat center / cover;}
/* 메인 페이지 - 기본 페이지 */
/* .mainPage .basicPage > div:not(:last-of-type){margin-bottom: 60px;} */
/* 메인 페이지 - 기본 페이지 - 텝 */
.mainPage .basicPage > div > ul{display: flex; padding-bottom: 20px; border-bottom: 1px solid var(--color-line); margin-bottom: 20px;}
.mainPage .basicPage > div > ul li:not(:first-of-type){display: flex; align-items: center;}
.mainPage .basicPage > div > ul li:not(:first-of-type)::before{content: ''; width: 1px; height: 16px; background-color: var(--color-line); margin-inline: 12px;}
.mainPage .basicPage > div > ul li button{font-size: 20px; line-height: 24px; font-weight: 700; color: var(--color-button);}
.mainPage .basicPage > div > ul li button.active{color: var(--color-font01);}
/* 메인 페이지 - 기본 페이지 - 셀렉트 */
.mainPage .basicPage .selectArea{display: flex; gap: 10px; margin-bottom: 20px;}
/* 메인 페이지 - 기본 페이지 - 정보 */
.mainPage .basicPage .infoArea{display: flex; gap: 13px;}
.mainPage .basicPage .infoArea .graphArea{flex: 1; padding-block: 20px;}
.mainPage .basicPage .infoArea .graphArea div.graph{width: 100%; height: 280px; /* aspect-ratio: 1;  */
    /* border: 2px solid var(--color-point01); */ /* box-sizing: border-box; */ border-radius: 100%; margin-inline: auto;
}
.mainPage .basicPage .infoArea .graphArea div.data-none{width: 320px; aspect-ratio: 1; background: url(../images/data-none-graph.png) no-repeat center / cover; margin: 40px auto 0; display: flex; justify-content: center; align-items: center;}
.mainPage .basicPage .infoArea .graphArea ul{max-width: 350px; margin-inline: auto; margin-top: 40px; display: flex; flex-wrap: wrap; row-gap: 12px;}
.mainPage .basicPage .infoArea .graphArea ul li{width: 33.33%; display: flex; align-items: center; font-size: 12px; font-weight: 400;}
.mainPage .basicPage .infoArea .graphArea ul li::before{content: ''; width: 16px; aspect-ratio: 1; margin-right: 4px; border-radius: 100%;}
/* .mainPage .basicPage .infoArea .graphArea ul li:nth-child(3n + 1){width: 32%;}
.mainPage .basicPage .infoArea .graphArea ul li:nth-child(3n + 2){width: 42.85%;}
.mainPage .basicPage .infoArea .graphArea ul li:nth-child(3n + 3){width: 25.15%;} */
/* .mainPage .basicPage .infoArea .graphArea ul li::before{background-color: var(--bgColor);} */
.mainPage .basicPage .infoArea .graphArea ul li:nth-child(1)::before{background-color: #FEA89B;;}
.mainPage .basicPage .infoArea .graphArea ul li:nth-child(2)::before{background-color: #FDBF6C;}
.mainPage .basicPage .infoArea .graphArea ul li:nth-child(3)::before{background-color: #FCD665;}
.mainPage .basicPage .infoArea .graphArea ul li:nth-child(4)::before{background-color: #7DDB83;}
.mainPage .basicPage .infoArea .graphArea ul li:nth-child(5)::before{background-color: #74CBCA;}
.mainPage .basicPage .infoArea .graphArea ul li:nth-child(6)::before{background-color: #79C0FC;}
.mainPage .basicPage .infoArea .graphArea ul li:nth-child(7)::before{background-color: #BF9DFC;}
.mainPage .basicPage .infoArea .graphArea ul li:nth-child(8)::before{background-color: #FFA6D7;}
.mainPage .basicPage .infoArea .graphArea ul li:nth-child(9)::before{background-color: #B78F8F;}
.mainPage .basicPage .infoArea .boardBox{width: 550px; flex-shrink: 0; align-content: center;}
.mainPage .basicPage .infoArea .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(1){flex: 50;}
.mainPage .basicPage .infoArea .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(2){flex: 120;}
.mainPage .basicPage .infoArea .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(n + 3):not(:last-child){flex: 110;}
/* .mainPage .basicPage .infoArea .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(4){flex: 110;} */
.mainPage .basicPage .infoArea .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):last-child{flex: 70;}
.mainPage .basicPage .infoArea .boardBox .board-center{max-height: 336px; overflow: auto;}
.mainPage .basicPage .infoArea .boardBox .board-center::-webkit-scrollbar{ 
    /* scrollbar 전체 설정 */
    width: 6px;    
    /* 세로 스크롤의 가로 크기 */
    /* 가로 스크롤의 세로 크기 */
}
.mainPage .basicPage .infoArea .boardBox .board-center::-webkit-scrollbar-track{width: 5px;}
.mainPage .basicPage .infoArea .boardBox .board-center::-webkit-scrollbar-thumb{
    width: 6px;
    background-color: rgba(99, 99, 99, 0.5);
    border-radius: 5px;
    /* 드래스할 수 있는 스크롤 손잡이 (scrollbar) */
}
.mainPage .basicPage .infoArea .boardBox .data-none{width: 100%; height: 336px; display: flex; justify-content: center; align-items: center;}

/* 메인 페이지 - 영업사원 - 매출 */
.mainPage .salesPage > div strong{font-size: 20px; line-height: 24px; font-weight: 700; display: block;}
.mainPage .salesPage .salesArea strong{margin-bottom: 20px;}
.mainPage .salesPage .salesArea > div{padding: 20px; border: 1px solid var(--color-line); display: flex; align-items: center; gap: 20px;}
.mainPage .salesPage .salesArea > div .progressArea{/* width: 500px; */ flex: 1; height: 40px; margin-inline: 40px; border: 1px solid var(--color-line); position: relative;}
.mainPage .salesPage .salesArea > div .progressArea::before{content: ''; position: absolute; left: 0; top: 0; height: 100%; width: var(--percent); background: linear-gradient(270deg, #E02C28 40%, #A51E23 100%);}
.mainPage .salesPage .salesArea > div .progressArea > *{position: absolute; ; top: 0; height: 100%;}
.mainPage .salesPage .salesArea > div .progressArea > *:is([title="투자금액"], [title="최소할당매출"], [title="투자금액"], [title="목표매출"]){border-right: 1px dotted white;}
.mainPage .salesPage .salesArea > div .progressArea > *[title="최소할당매출"]{left: var(--percent);}
.mainPage .salesPage .salesArea > div .progressArea > *[title="현재매출"]{left: var(--percent); font-weight: 700;}
.mainPage .salesPage .salesArea > div .progressArea > *::before{content: attr(title); position: absolute; /* top: 100%; */ left: -50%; white-space: nowrap;}
.mainPage .salesPage .salesArea > div .progressArea > :is(*[title="투자금액"], *[title="최소할당매출"], *[title="목표매출"])::before{top: 0%; padding-bottom: 16px; transform: translateX(-50%) translateY(-100%); background: url(../images/icon-triangle-gray-down.png) no-repeat center bottom 3px / 12px;}
.mainPage .salesPage .salesArea > div .progressArea > :is(*[title="현재매출"])::before{top: 100%; padding-top: 16px; transform: translateX(-50%); background: url(../images/icon-triangle-gray-up.png) no-repeat center top 3px / 12px;}
.mainPage .salesPage .salesArea > div .progressArea > *[title="현재매출"]::after{content: attr(data-percent) '% 달성'; position: absolute; top: calc(100% + 40px); transform: translateX(-50%); font-size: 12px; line-height: 14px; color: var(--color-point01); padding: 5px 10px; white-space: nowrap; background-color: var(--color-point02); border-radius: 20px;}
.mainPage .salesPage .salesArea > div .progressArea.month > *[title="투자금액"]{left: var(--percent);}
.mainPage .salesPage .salesArea > div .progressArea.month > *[title="목표매출"]{left: var(--percent);}
.mainPage .salesPage .salesArea > div .progressArea.day > *[title="투자금액"]{left: var(--percent);}
.mainPage .salesPage .salesArea > div .amountArea{width: 360px; flex-shrink: 0;}
.mainPage .salesPage .salesArea > div .amountArea b{font-size: 15px; line-height: 18px; font-weight: 700; display: block; margin-bottom: 12px;}
.mainPage .salesPage .salesArea > div .amountArea dl{display: flex; border-bottom: 1px solid var(--color-division);}
.mainPage .salesPage .salesArea > div .amountArea dl:first-of-type{border-top: 1px solid var(--color-division);}
.mainPage .salesPage .salesArea > div .amountArea dl :is(dt, dd){padding: 12px 10px;}
.mainPage .salesPage .salesArea > div .amountArea dl dt{width: 120px; background-color: var(--color-face);}
.mainPage .salesPage .salesArea > div .amountArea dl dd{flex: 1;}
.mainPage .salesPage .salesArea > div .amountArea mark{font-weight: 700; text-align: center; color: var(--color-point01); display: block; margin-top: 12px;}
/* 메인 페이지 - 영업사원 - 현황 */
/* .mainPage .salesPage .boardBox{gap: 15px 10px;} */
.mainPage .salesPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(1){flex: 140;}
.mainPage .salesPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(2){flex: 100;}
.mainPage .salesPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(3){flex: 120;}
.mainPage .salesPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(4){flex: 140;}
.mainPage .salesPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(5){flex: 150;}
.mainPage .salesPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(6){flex: 100;}
.mainPage .salesPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(7){flex: 100;}
.mainPage .salesPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(8){flex: 80;}


/* 통합 DB 관리 - 통합 고객 목록 */
.customerPage.listPage{}
.customerPage.listPage .boardBox ul{width: 100%; display: flex; align-items: center; gap: 30px; padding-top: 20px; margin-block: 15px 30px; border-top: 1px solid var(--color-line);}
.customerPage.listPage .boardBox ul li{display: flex; align-items: center;}
.customerPage.listPage .boardBox ul li::before{content: ''; width: 14px; aspect-ratio: 1; border-radius: 100%; margin-right: 6px;}
.customerPage.listPage .boardBox ul li:before{background-color: var(--color);}
.customerPage.listPage .boardBox .btn-gray-black:not(.boundary){margin-left: 7px;}
.customerPage.listPage .boardBox [class*="selectBox"]{max-width: 130px;}
.customerPage.listPage .boardBox [class*="selectBox"]:has(+ .selectBox){margin-right: 7px;}
.customerPage.listPage > .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(1){flex: 26;}
.customerPage.listPage > .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(2){flex: 56;}
.customerPage.listPage > .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(3){flex: 101;}
.customerPage.listPage > .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(4){flex: 56;}
.customerPage.listPage > .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(5){flex: 80;}
.customerPage.listPage > .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(6){flex: 81;}
.customerPage.listPage > .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(7){flex: 131;}
.customerPage.listPage > .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(8){flex: 79;}
.customerPage.listPage > .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(9){flex: 79;}
.customerPage.listPage > .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(10){flex: 79;}
.customerPage.listPage > .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(11){flex: 79;}
.customerPage.listPage > .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(12){flex: 56;}
.customerPage.listPage > .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(13){flex: 23;}
.customerPage.listPage > .boardBox .board-center li > *:not(:last-child){display: flex; align-items: center; height: 100%; border-right: 1px solid var(--color-division);}
.customerPage.listPage > .boardBox .board-center li > p span{text-overflow: ellipsis; overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2;}


/* 통합 DB 관리 - 고객 등록 */
.customerPage:is(.registrationPage, .updatePage) :is(.ant-picker:has(~ .ant-picker), .ant-picker ~ .ant-picker){flex: 1;}
.customerPage.updatePage > div:not(:last-of-type){margin-bottom: 20px;}
.customerPage.updatePage .boardBox > button[data-count]{color: var(--color-font03); font-weight: 700;}
.customerPage.updatePage .boardBox > button[data-count]:not(:last-of-type){margin-right: 20px;}
.customerPage.updatePage .boardBox > button[data-count]::after{content: ' (' attr(data-count) ')';}
.customerPage.updatePage .boardBox > button[data-count].active{color: var(--color-point01); position: relative;}
.customerPage.updatePage .boardBox > button[data-count].active::before{content: ''; width: 100%; height: 2px; position: absolute; left: 0; bottom: -15px; background-color: var(--color-point01);}
.customerPage.updatePage .boardBox > .choice{margin-left: auto;}
.customerPage.updatePage .boardBox > .btn-gray-black{position: relative;}
.customerPage.updatePage .boardBox > .btn-gray-black::after{content: ''; width: 1px; height: 50%; background-color: var(--color-line); position: absolute; right: -15px; top: 50%; translate: 0 -50%;}
.customerPage.updatePage .boardBox .total{font-size: 13px; margin-left: auto;}
.customerPage.updatePage .boardBox > .choice ~ .total{margin-left: 30px;}
.customerPage.updatePage .boardBox .board-scroll1 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(1){flex: 16;}
.customerPage.updatePage .boardBox .board-scroll1 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(2){flex: 120;}
.customerPage.updatePage .boardBox .board-scroll1 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(3){flex: 540;}
.customerPage.updatePage .boardBox .board-scroll1 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(4){flex: 130;}
.customerPage.updatePage .boardBox .board-scroll1 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(5){flex: 120;}
.customerPage.updatePage .boardBox .board-scroll1 .board-center li{min-height: 56px; height: auto; padding-block: 12px; box-sizing: border-box;}
.customerPage.updatePage .boardBox .consultInputArea{width: 100%; --padding: 0; border: none; margin-top: 30px; padding-top: 30px; border-top: 1px solid var(--color-division);}
.customerPage.updatePage .boardBox .consultInputArea > b{margin-bottom: 30px;}
.customerPage.updatePage .boardBox .consultInputArea fieldset ul li div:has(.selectBox-time-hour){align-items: center;}
.customerPage.updatePage .boardBox .consultInputArea fieldset ul li div:has(.selectBox-time-hour) .ant-picker{flex: 2;}
.customerPage.updatePage .boardBox .consultInputArea fieldset ul li div:has(.selectBox-time-hour) [class*="selectBox-time"]{flex: 1;}
.customerPage.updatePage .boardBox .board-scroll2 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(1){flex: 16;}
.customerPage.updatePage .boardBox .board-scroll2 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(2){flex: 80;}
.customerPage.updatePage .boardBox .board-scroll2 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(3){flex: 90;}
.customerPage.updatePage .boardBox .board-scroll2 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(4){flex: 70;}
.customerPage.updatePage .boardBox .board-scroll2 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(5){flex: 90;}
.customerPage.updatePage .boardBox .board-scroll2 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(6){flex: 90;}
.customerPage.updatePage .boardBox .board-scroll2 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(7){flex: 80;}
.customerPage.updatePage .boardBox .board-scroll2 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(8){flex: 90;}
.customerPage.updatePage .boardBox .board-scroll2 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(9){flex: 80;}
.customerPage.updatePage .boardBox .board-scroll2 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(10){flex: 120;}
.customerPage.updatePage .boardBox .board-scroll2 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(11){flex: 60;}
.customerPage.updatePage .boardBox .board-scroll3 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(1){flex: 120;}
.customerPage.updatePage .boardBox .board-scroll3 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(2){flex: 180;}
.customerPage.updatePage .boardBox .board-scroll3 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(3){flex: 160;}
.customerPage.updatePage .boardBox .board-scroll3 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(4){flex: 180;}
.customerPage.updatePage .boardBox .board-scroll3 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(5){flex: 130;}
.customerPage.updatePage .boardBox .board-scroll3 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(6){flex: 110;}
.customerPage.updatePage .boardBox .board-scroll4 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(1){flex: 80; min-width: 80px;}
.customerPage.updatePage .boardBox .board-scroll4 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(2){flex: 90; min-width: 90px;}
.customerPage.updatePage .boardBox .board-scroll4 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(3){flex: 90; min-width: 90px;}
.customerPage.updatePage .boardBox .board-scroll4 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(4){flex: 90; min-width: 90px;}
.customerPage.updatePage .boardBox .board-scroll4 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(5){flex: 80; min-width: 80px;}
.customerPage.updatePage .boardBox .board-scroll4 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(6){flex: 90; min-width: 90px;}
.customerPage.updatePage .boardBox .board-scroll4 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(7){flex: 80; min-width: 80px;}
.customerPage.updatePage .boardBox .board-scroll4 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(8){flex: 90; min-width: 90px;}
.customerPage.updatePage .boardBox .board-scroll4 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(9){flex: 120; min-width: 120px;}
.customerPage.updatePage .boardBox .board-scroll4 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(10){flex: 90; min-width: 90px;}
.customerPage.updatePage .boardBox .board-scroll4 :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(11){flex: 70; min-width: 70px;}



/* 통합 DB 관리 - 고객 등록 - 대량 고객 등록*/
.customerPage.bulkPage input[type="text"].active{background-color: white;}


/* 결제 관리 - 목록 */
.paymentPage.listPage > .boardBox > .board-table :is(thead, tbody) tr > *:nth-child(1){width: 400px;}
.paymentPage.listPage .boardBox .board-scroll :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(1){flex: 70; min-width: 70px;}
.paymentPage.listPage .boardBox .board-scroll :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(2){flex: 80; min-width: 80px;}
.paymentPage.listPage .boardBox .board-scroll :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(3){flex: 100; min-width: 100px;}
.paymentPage.listPage .boardBox .board-scroll :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(4){flex: 80; min-width: 80px;}
.paymentPage.listPage .boardBox .board-scroll :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(5){flex: 80; min-width: 80px;}
.paymentPage.listPage .boardBox .board-scroll :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(6){flex: 80; min-width: 80px;}
.paymentPage.listPage .boardBox .board-scroll :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(7){flex: 80; min-width: 80px;}
.paymentPage.listPage .boardBox .board-scroll :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(8){flex: 90; min-width: 90px;}
.paymentPage.listPage .boardBox .board-scroll :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(9){flex: 120; min-width: 120px;}
.paymentPage.listPage .boardBox .board-scroll :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(10){flex: 80; min-width: 80px;}
.paymentPage.listPage .boardBox .board-scroll :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(11){flex: 80; min-width: 80px;}
.paymentPage.listPage .boardBox .board-scroll :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(12){flex: 90; min-width: 90px;}
.paymentPage.listPage .boardBox .board-scroll :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(13){flex: 80; min-width: 80px;}
.paymentPage.listPage .boardBox .board-scroll :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(14){flex: 80; min-width: 80px;}
.paymentPage.listPage .boardBox .board-scroll :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(15){flex: 190; min-width: 190px;}
.paymentPage.listPage .boardBox .board-scroll :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(16){flex: 190; min-width: 190px;}


/* 결제 관리 - 대량 결제 등록*/
.paymentPage .bulkBox input[type="text"].active{background-color: white;}


/* 결제 관리 - 상품 목록 */
.paymentPage.productPage > .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(1){flex: 26;}
.paymentPage.productPage > .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(2){flex: 100;}
.paymentPage.productPage > .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(3){flex: 300;}
.paymentPage.productPage > .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(4){flex: 140;}
.paymentPage.productPage > .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(5){flex: 140;}
.paymentPage.productPage > .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(6){flex: 164;}
.paymentPage.productPage > .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(7){flex: 50;}


/* 통계 - 그룹/계정별 유효성 */
.statisticsPage:is(.accountPage, .campaignPage) .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(1){flex: 70;}
.statisticsPage:is(.accountPage, .campaignPage) .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(2){flex: 130;}
.statisticsPage:is(.accountPage, .campaignPage) .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(3){flex: 50;}
.statisticsPage:is(.accountPage, .campaignPage) .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(4){flex: 50;}
.statisticsPage:is(.accountPage, .campaignPage) .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(5){flex: 50;}
.statisticsPage:is(.accountPage, .campaignPage) .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(6){flex: 54;}
.statisticsPage:is(.accountPage, .campaignPage) .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(7){flex: 50;}
.statisticsPage:is(.accountPage, .campaignPage) .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(8){flex: 50;}
.statisticsPage:is(.accountPage, .campaignPage) .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(9){flex: 60;}
.statisticsPage:is(.accountPage, .campaignPage) .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(10){flex: 50;}
.statisticsPage:is(.accountPage, .campaignPage) .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(11){flex: 70;}
.statisticsPage:is(.accountPage, .campaignPage) .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(12){flex: 50;}
.statisticsPage:is(.accountPage, .campaignPage) .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(13){flex: 45;}
.statisticsPage:is(.accountPage, .campaignPage) .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(14){flex: 61;}


/* 통계 - 그룹/계정별 유효성 - 그룹별, 계정별 */
.statisticsPage.byPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(1){flex: 40;}
.statisticsPage.byPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(2){flex: 90;}
.statisticsPage.byPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(3){flex: 50;}
.statisticsPage.byPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(4){flex: 50;}
.statisticsPage.byPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(5){flex: 54;}
.statisticsPage.byPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(6){flex: 50;}
.statisticsPage.byPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(7){flex: 50;}
.statisticsPage.byPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(8){flex: 60;}
.statisticsPage.byPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(9){flex: 50;}
.statisticsPage.byPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(10){flex: 70;}
.statisticsPage.byPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(11){flex: 50;}
.statisticsPage.byPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(12){flex: 55;}
.statisticsPage.byPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(13){flex: 70;}
.statisticsPage.byPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(14){flex: 50;}
.statisticsPage.byPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(15){flex: 50;}


/* 통계 - 매출현황 */
.statisticsPage.salesPage .boardBox > b:not([class]){font-size: 16px; line-height: 19px; font-weight: 700;}
.statisticsPage.salesPage .boardBox > .total{width: 100%; margin-top: 30px;}
.statisticsPage.salesPage .boardBox > .board-search{margin-left: auto; width: 260px;}
.statisticsPage.salesPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(1){flex: 70;}
.statisticsPage.salesPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(2){flex: 90;}
.statisticsPage.salesPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(3){flex: 70;}
.statisticsPage.salesPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(4){flex: 100;}
.statisticsPage.salesPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(5){flex: 100;}
.statisticsPage.salesPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(6){flex: 100;}
.statisticsPage.salesPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(7){flex: 100;}
.statisticsPage.salesPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(8){flex: 90;}
.statisticsPage.salesPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(9){flex: 90;}
.statisticsPage.salesPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(10){flex: 80;}


/* 시스템 관리 - 기본 설정 - 사용자 목록 */
.systemPage.anUserPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(1){flex: 26;}
.systemPage.anUserPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(2){flex: 116.28;}
.systemPage.anUserPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(3){flex: 116.28;}
.systemPage.anUserPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(4){flex: 116.28;}
.systemPage.anUserPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(5){flex: 116.28;}
.systemPage.anUserPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(6){flex: 116.28;}
.systemPage.anUserPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(7){flex: 116.28;}
.systemPage.anUserPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(8){flex: 116.28;}
.systemPage.anUserPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(9){flex: 60;}


/* 시스템 관리 - 기본 설정 - 부서 관리 */
.systemPage.bureauPage .addBtn{display: flex;}
.systemPage.bureauPage .horizontalTwo .bureauBox .addBtn{margin-inline: 20px; padding-block: 15px; gap: 7px; margin-top: 10px; border-top: 1px solid var(--color-line);}
.systemPage.bureauPage .horizontalTwo .bureauBox .addBtn button{flex: 1;}
.systemPage.bureauPage .horizontalTwo .bureauBox .listArea > ul{max-height: 350px;}
.systemPage.bureauPage [class*="bureau"] fieldset > strong{font-size: 15px; line-height: 18px; font-weight: 700; display: block; margin-bottom: 30px;}
.systemPage.bureauPage [class*="bureau"] fieldset > ul > li:not(:last-child){margin-bottom: 20px;}
.systemPage.bureauPage [class*="bureau"] fieldset > ul > li label{font-weight: 700;}
.systemPage.bureauPage [class*="bureau"] fieldset > ul > li > div{margin-top: 10px;}
.systemPage.bureauPage [class*="bureau"] fieldset > ul > li > .bureauBox .listArea > ul{max-height: 170px;}
.systemPage.bureauPage [class*="bureau"] fieldset > ul > li > .bureauBox .addBtn{align-items: center; gap: 10px; padding-block: 10px; margin-top: 0; border-top: 1px solid var(--color-line);}
.systemPage.bureauPage [class*="bureau"] fieldset > ul > li > .bureauBox .addBtn button{margin-left: auto;}
.systemPage.bureauPage .horizontalTwo .boardArea > div:not(:first-of-type){margin-top: 40px;}
.systemPage.bureauPage .horizontalTwo .boardArea .boardBox > .btn-gray-black:not(:last-of-type){margin-right: 7px;}
.systemPage.bureauPage .horizontalTwo .boardArea > .boardBox > .board-top *:nth-child(1), 
.systemPage.bureauPage .horizontalTwo .boardArea > .boardBox > .board-center li > *:nth-child(1){flex: 16;}
.systemPage.bureauPage .horizontalTwo .boardArea > .boardBox > .board-top *:nth-child(2), 
.systemPage.bureauPage .horizontalTwo .boardArea > .boardBox > .board-center li > *:nth-child(2){flex: 94;}
.systemPage.bureauPage .horizontalTwo .boardArea > .boardBox > .board-top *:nth-child(3), 
.systemPage.bureauPage .horizontalTwo .boardArea > .boardBox > .board-center li > *:nth-child(3){flex: 100;}
.systemPage.bureauPage .horizontalTwo .boardArea > .boardBox > .board-top *:nth-child(4), 
.systemPage.bureauPage .horizontalTwo .boardArea > .boardBox > .board-center li > *:nth-child(4){flex: 110;}
.systemPage.bureauPage .horizontalTwo .boardArea > .boardBox > .board-top *:nth-child(5), 
.systemPage.bureauPage .horizontalTwo .boardArea > .boardBox > .board-center li > *:nth-child(5){flex: 155;}
.systemPage.bureauPage .horizontalTwo .boardArea > .boardBox > .board-top *:nth-child(6), 
.systemPage.bureauPage .horizontalTwo .boardArea > .boardBox > .board-center li > *:nth-child(6){flex: 65;}


/* 시스템 관리 - 기본 설정 - 고객 구분 관리 */
.systemPage.clientPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(1){flex: 26;}
.systemPage.clientPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(2){flex: 104;}
.systemPage.clientPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(3){flex: 110;}
.systemPage.clientPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(4){flex: 70;}
.systemPage.clientPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(5){flex: 100;}
.systemPage.clientPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(6){flex: 120;}
.systemPage.clientPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(7){flex: 120;}
.systemPage.clientPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(8){flex: 90;}
.systemPage.clientPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(9){flex: 90;}
.systemPage.clientPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(10){flex: 60;}


/* 시스템 관리 - 기본 설정 - 고객목록 설정 */
/* .systemPage.customerPage .dropBox .autoArea > b{display: block; font-size: 15px; line-height: 18px; font-weight: 700; margin-bottom: 30px;} */
.systemPage.customerPage .dropBox .autoArea > label:not(:first-of-type){margin-top: 20px;}
.systemPage.customerPage .dropBox .autoArea > div{display: inline-block; margin-left: 22px; margin-top: 10px; padding: 20px; background-color: var(--color-face);}
.systemPage.customerPage .dropBox .autoArea > div ul{display: flex; gap: 40px;}
.systemPage.customerPage .dropBox .autoArea > div ul li > label:first-child{font-weight: 700;}
.systemPage.customerPage .dropBox .autoArea > div ul li div{height: 32px; display: flex; align-items: center; gap: 20px; margin-top: 10px;}
.systemPage.customerPage .dropBox .autoArea > div ul li:has([type="search"]){width: 260px;}


/* 시스템 관리 - 기본 설정 - 공통 코드 관리 */
.systemPage.commonPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(1){flex: 26;}
.systemPage.commonPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(2){flex: 137.3;}
.systemPage.commonPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(3){flex: 137.3;}
.systemPage.commonPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(4){flex: 137.3;}
.systemPage.commonPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(5){flex: 137.3;}
.systemPage.commonPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(6){flex: 137.3;}
.systemPage.commonPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(7){flex: 137.3;}
.systemPage.commonPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(8){flex: 60;}


/* 시스템 관리 - 기본 설정 - 속성값 설정 - 구분값 설정 */
.systemPage.propertyPage .divisionArea > div:first-child{width: 230px; min-height: 300px; padding-block: 10px; border: 1px solid var(--color-line);}
.systemPage.propertyPage .divisionArea > div:first-child button{width: 100%; height: 36px; font-size: 13px; font-weight: 400; text-align: left; padding-inline: 20px;}
.systemPage.propertyPage .divisionArea > div:first-child button.active{color: var(--color-point01); font-weight: 700; display: flex; align-items: center;}
.systemPage.propertyPage .divisionArea > div:first-child button.active::after{content: ''; width: 16px; aspect-ratio: 1; background: url(../images/icon-check.png) no-repeat center / 100%; margin-left: 6px;}
.systemPage.propertyPage .divisionArea > .boardBox{column-gap: 7px;}
.systemPage.propertyPage .divisionArea > .boardBox b{font-size: 15px; font-weight: 700; margin-right: auto;}
.systemPage.propertyPage .divisionArea > .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(1){flex: 36;}
.systemPage.propertyPage .divisionArea > .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(2){flex: 439;}
.systemPage.propertyPage .divisionArea > .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(3){flex: 215;}

/* 시스템 관리 - 기본 설정 - 속성값 설정 - 투자금액/목표량 설정 */
.systemPage.propertyPage .moneyArea > b{font-size: 16px; line-height: 19px; font-weight: 700;}
.systemPage.propertyPage .moneyArea .horizontalTwo{margin-top: 30px;}
.systemPage.propertyPage .moneyArea .horizontalTwo > div.calendar{width: 230px; height: 240px; border: 1px solid var(--color-line); display: flex; flex-direction: column;}
.systemPage.propertyPage .moneyArea .horizontalTwo > div.calendar .yearArea{height: 46px; display: flex; justify-content: space-between; align-items: center; padding-inline: 7px; border-bottom: 1px solid var(--color-line);}
.systemPage.propertyPage .moneyArea .horizontalTwo > div.calendar .yearArea span{font-weight: 700;}
.systemPage.propertyPage .moneyArea .horizontalTwo > div.calendar .yearArea button{width: 24px; aspect-ratio: 24 / 32; background: url(../images/icon-pagination.png) no-repeat center top calc(33.33% * var(--styleIdx)) / 100%; text-indent: -9999px;}
.systemPage.propertyPage .moneyArea .horizontalTwo > div.calendar .yearArea button:nth-of-type(1){--styleIdx: 1;}
.systemPage.propertyPage .moneyArea .horizontalTwo > div.calendar .yearArea button:nth-of-type(2){--styleIdx: 3;}
.systemPage.propertyPage .moneyArea .horizontalTwo > div.calendar .monthArea{flex: 1; display: flex; flex-wrap: wrap; padding: 5px 10px;}
.systemPage.propertyPage .moneyArea .horizontalTwo > div.calendar .monthArea li{width: calc(100% / 3); padding: 15px 20px; text-align: center; box-sizing: border-box; cursor: pointer;}
.systemPage.propertyPage .moneyArea .horizontalTwo > div.calendar .monthArea li.active{font-weight: 700; color: var(--color-point01);}
.systemPage.propertyPage .moneyArea .horizontalTwo > form fieldset ul{display: flex; gap: 40px;}
.systemPage.propertyPage .moneyArea .horizontalTwo > form fieldset ul li:nth-child(1){flex: 358;}
.systemPage.propertyPage .moneyArea .horizontalTwo > form fieldset ul li:nth-child(2){flex: 338;}
.systemPage.propertyPage .moneyArea .horizontalTwo > form fieldset ul li > label:first-child{font-size: 15px; line-height: 18px; font-weight: 700;}
.systemPage.propertyPage .moneyArea .horizontalTwo > form fieldset ul li > div{display: flex; align-items: center;}
.systemPage.propertyPage .moneyArea .horizontalTwo > form fieldset ul li > div:first-of-type{margin-top: 22px;}
.systemPage.propertyPage .moneyArea .horizontalTwo > form fieldset ul li > div:not(:first-of-type){margin-top: 10px;}
.systemPage.propertyPage .moneyArea .horizontalTwo > form fieldset ul li > div input[type="radio"] + label{font-weight: 700; margin-right: auto;}
.systemPage.propertyPage .moneyArea .horizontalTwo > form fieldset ul li > div p{margin-right: 10px;}
.systemPage.propertyPage .moneyArea .horizontalTwo > form fieldset ul li > div input[type="radio"] ~ p{color: var(--color-button);}
.systemPage.propertyPage .moneyArea .horizontalTwo > form fieldset ul li > div input[type="radio"]:checked ~ p{color: var(--color-font01);}
.systemPage.propertyPage .moneyArea .horizontalTwo > form fieldset ul li:nth-child(1) > div div{width: 150px;}
.systemPage.propertyPage .moneyArea .horizontalTwo > form fieldset ul li:nth-child(2) > div div{width: 100px;}
.systemPage.propertyPage .moneyArea .horizontalTwo > form fieldset > input[type="submit"]{display: block; margin-left: auto; margin-top: 94px;}


/* 시스템 관리 - 권한 설정 - 역할 관리 */
.systemPage.managementPage :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(1){flex: 26;}
.systemPage.managementPage :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(2){flex: 166.75;}
.systemPage.managementPage :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(3){flex: 166.75;}
.systemPage.managementPage :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(4){flex: 166.75;}
.systemPage.managementPage :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(5){flex: 166.75;}
.systemPage.managementPage :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(6){flex: 166.75;}
.systemPage.managementPage :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(7){flex: 60;}


/* 시스템 관리 - 권한 설정 - 역할 관리 - 추가 */
.systemPage .dropBox .limitArea > label{margin-bottom: 15px;}
.systemPage .dropBox .limitArea > div:not(:last-of-type){margin-bottom: 30px;}
.systemPage .dropBox .limitArea .timeArea{display: flex; align-items: center; gap: 5px;}
.systemPage .dropBox .limitArea .ipArea{display: flex; align-items: flex-start;}
.systemPage .dropBox .limitArea .ipArea input{width: 200px;}
.systemPage .dropBox .limitArea .ipArea .btn-gray-black{margin-left: 7px;}
.systemPage .dropBox .limitArea .ipArea ul{width: 260px; height: 96px; padding: 12px 20px; box-sizing: border-box; overflow: auto; background-color: var(--color-division); margin-left: 20px;}
.systemPage .dropBox .limitArea .ipArea ul::-webkit-scrollbar{width: 6px;}
.systemPage .dropBox .limitArea .ipArea ul li{display: flex; align-items: center;}
.systemPage .dropBox .limitArea .ipArea ul li:not(:last-child){margin-bottom: 12px;}
.systemPage .dropBox .limitArea .ipArea ul li button{width: 16px; aspect-ratio: 1; background: url(../images/icon-delete.png) no-repeat center / cover; text-indent: -9999px; margin-left: 6px;}


/* 시스템 관리 - 권한 설정 - 역할 권한 관리 */
.systemPage.permissionsPage .horizontalTwo .boardArea{display: flex; flex-wrap: wrap;}
.systemPage.permissionsPage .horizontalTwo .boardArea > strong{width: 100%; margin-bottom: 30px;}
.systemPage.permissionsPage .horizontalTwo .boardArea > button{color: var(--color-font03); font-weight: 700;}
.systemPage.permissionsPage .horizontalTwo .boardArea > button:not(:last-of-type){margin-right: 20px;}
.systemPage.permissionsPage .horizontalTwo .boardArea > button.active{color: var(--color-point01); position: relative;}
.systemPage.permissionsPage .horizontalTwo .boardArea > button.active::before{content: ''; width: 100%; height: 2px; position: absolute; left: 0; bottom: -15px; background-color: var(--color-point01);}
.systemPage.permissionsPage .horizontalTwo .boardArea > [class*="Area"]{display: flex; flex-wrap: wrap; width: 100%;}
.systemPage.permissionsPage .horizontalTwo .boardArea > [class*="Area"] > [class*="selectBox"]{max-width: 200px;}
.systemPage.permissionsPage .horizontalTwo .boardArea > [class*="Area"] > .searchArea{width: 260px; margin-left: 7px;}
.systemPage.permissionsPage .horizontalTwo .boardArea .boardBox{width: 100%; margin-top: 30px;}
/* 시스템 관리 - 권한 설정 - 역할 권한 관리 - 화면 권한 */
.systemPage.permissionsPage .horizontalTwo .boardArea .screenArea > .boardBox .board-center{border-top: 1px solid var(--color-division);}
.systemPage.permissionsPage .horizontalTwo .boardArea .screenArea > .boardBox :is(.board-top, .board-center li, .board-bottom){gap: 0; align-items: stretch; padding-right: 0px;}
.systemPage.permissionsPage .horizontalTwo .boardArea .screenArea > .boardBox :is(.board-top > *, .board-center li > *, .board-bottom *):not(:first-child){padding-left: 10px;}
.systemPage.permissionsPage .horizontalTwo .boardArea .screenArea > .boardBox :is(.board-top > *, .board-center li > *, .board-bottom *):not(:last-child)::after{right: 0px;}
.systemPage.permissionsPage .horizontalTwo .boardArea .screenArea > .boardBox :is(.board-top > *, .board-center li > *, .board-bottom *):first-child::after{right: -4px;}
.systemPage.permissionsPage .horizontalTwo .boardArea .screenArea > .boardBox :is(.board-top > *, .board-center li > *, .board-bottom *){display: flex; align-items: center;}
.systemPage.permissionsPage .horizontalTwo .boardArea .screenArea > .boardBox .board-top > div > label:has(+ .selectBoard-role){flex: none;}
.systemPage.permissionsPage .horizontalTwo .boardArea .screenArea > .boardBox .board-top > div > label:has(+ .selectBoard-role)::before{margin-right: 0;}
.systemPage.permissionsPage .horizontalTwo .boardArea .screenArea > .boardBox :is(.board-top > *, .board-center li > *, .board-bottom *):nth-child(n + 5):nth-child(-n + 6){background-color: #FFF4E6;}
.systemPage.permissionsPage .horizontalTwo .boardArea .screenArea > .boardBox :is(.board-top > *, .board-center li > *, .board-bottom *):nth-child(n + 7):nth-child(-n + 10){background-color: #F7FFE6;}
.systemPage.permissionsPage .horizontalTwo .boardArea .screenArea > .boardBox .board-center li:has( > *:first-child input[type="checkbox"]:checked) > *:nth-child(n + 5){background-color: #FFF9F9;}
.systemPage.permissionsPage .horizontalTwo .boardArea .screenArea > .boardBox :is(.board-top > *, .board-center li > *, .board-bottom *):nth-child(1){flex: 16;}
.systemPage.permissionsPage .horizontalTwo .boardArea .screenArea > .boardBox :is(.board-top > *, .board-center li > *, .board-bottom *):nth-child(2){flex: 84;}
.systemPage.permissionsPage .horizontalTwo .boardArea .screenArea > .boardBox :is(.board-top > *, .board-center li > *, .board-bottom *):nth-child(3){flex: 109;}
.systemPage.permissionsPage .horizontalTwo .boardArea .screenArea > .boardBox :is(.board-top > *, .board-center li > *, .board-bottom *):nth-child(4){flex: 25;}
.systemPage.permissionsPage .horizontalTwo .boardArea .screenArea > .boardBox :is(.board-top > *, .board-center li > *, .board-bottom *):nth-child(5){flex: 25;}
.systemPage.permissionsPage .horizontalTwo .boardArea .screenArea > .boardBox :is(.board-top > *, .board-center li > *, .board-bottom *):nth-child(6){flex: 25;}
.systemPage.permissionsPage .horizontalTwo .boardArea .screenArea > .boardBox :is(.board-top > *, .board-center li > *, .board-bottom *):nth-child(7){flex: 35;}
.systemPage.permissionsPage .horizontalTwo .boardArea .screenArea > .boardBox :is(.board-top > *, .board-center li > *, .board-bottom *):nth-child(8){flex: 25;}
.systemPage.permissionsPage .horizontalTwo .boardArea .screenArea > .boardBox :is(.board-top > *, .board-center li > *, .board-bottom *):nth-child(9){flex: 25;}
.systemPage.permissionsPage .horizontalTwo .boardArea .screenArea > .boardBox :is(.board-top > *, .board-center li > *, .board-bottom *):nth-child(10){flex: 25;}
/* 시스템 관리 - 권한 설정 - 역할 권한 관리 - 사용자 권한 */
.systemPage.permissionsPage .horizontalTwo .boardArea .userArea > .boardBox :is(.board-top > *, .board-center li > *, .board-bottom *):nth-child(1){flex: 16;}
.systemPage.permissionsPage .horizontalTwo .boardArea .userArea > .boardBox :is(.board-top > *, .board-center li > *, .board-bottom *):nth-child(2){flex: 244;}
.systemPage.permissionsPage .horizontalTwo .boardArea .userArea > .boardBox :is(.board-top > *, .board-center li > *, .board-bottom *):nth-child(3){flex: 210;}
.systemPage.permissionsPage .horizontalTwo .boardArea .userArea > .boardBox :is(.board-top > *, .board-center li > *, .board-bottom *):nth-child(4){flex: 210;}
/* 시스템 관리 - 권한 설정 - 역할 권한 관리 - 사용자 권한 */
/* .systemPage.permissionsPage .horizontalTwo .boardArea .boardBox .userArea :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(1){flex: 26;}
.systemPage.permissionsPage .horizontalTwo .boardArea .boardBox .userArea :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(2){flex: 254;}
.systemPage.permissionsPage .horizontalTwo .boardArea .boardBox .userArea :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(3){flex: 220;}
.systemPage.permissionsPage .horizontalTwo .boardArea .boardBox .userArea :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(4){flex: 210;} */
/* 시스템 관리 - 권한 설정 - 역할 권한 관리 - 검색 결과 */
.systemPage.permissionsPage .horizontalTwo .searchResultArea b{font-size: 15px; font-weight: 700;}
.systemPage.permissionsPage .horizontalTwo .searchResultArea ul li{display: flex; align-items: center; gap: 10px;}
.systemPage.permissionsPage .horizontalTwo .searchResultArea ul li:not(:last-child){margin-bottom: 15px;}
/* 시스템 관리 - 권한 설정 - 역할 권한 관리 - 추가 팝업 */
.popupBox .rolePopup{display: flex; justify-content: center; flex-wrap: wrap; column-gap: 10px;}
.popupBox .rolePopup [class*="selectBox"]{width: 140px;}
.popupBox .rolePopup .searchArea{width: 220px;}
.popupBox .rolePopup .boardBox{width: 100%;}
.popupBox .rolePopup .boardBox .board-center{height: 200px;}
.popupBox .rolePopup .boardBox :is(.board-top, .board-center li, .board-bottom){height: 40px;}
.userArea .popupBox .rolePopup .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(1){flex: 120;}
.userArea .popupBox .rolePopup .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(2){flex: 120;}
.userArea .popupBox .rolePopup .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(3){flex: 120;}
.userArea .popupBox .rolePopup .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(4){flex: 60;}
.screenArea .popupBox .rolePopup .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(1){flex: 120;}
.screenArea .popupBox .rolePopup .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(2){flex: 240;}
.screenArea .popupBox .rolePopup .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(3){flex: 60;}
.popupBox .rolePopup > [class*="choice"]{margin-top: 20px;}


/* 시스템 관리 - 회원사 관리 - 사용자접속이력 */
.systemPage.connectPage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *){flex: 1;}


/* 시스템 관리 - 회원사 관리 - 고객삭제이력 */
.systemPage.deletePage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *){flex: 1;}


/* 공지사항 */
.noticePage .bureauBox .listArea > ul{max-height: 400px;}
.noticePage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(1){flex: 220;}
.noticePage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(2){flex: 220;}
.noticePage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(3){flex: 220;}
.noticePage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):nth-child(4){flex: 220;}
.noticePage .boardBox :is(.board-top *, .board-center li > *, .board-bottom *):last-child{flex: 60;}
/* 공지사항 - 등록 */
/* .noticePage.announcementPage .dropBox form fieldset > ul li:first-child > div label:first-of-type{margin-right: 20px;} */
/* .noticePage.registrationPage .dropBox fieldset ul li div{align-items: flex-start;} */
.noticePage .dropBox fieldset ul li div .bureauList{flex: 1; display: flex; flex-wrap: wrap; align-items: center; gap: 12px 20px; padding: 8px 10px; border: 1px solid var(--color-line); border-radius: 4px;}
.noticePage .popupBox .childrenArea > b{content: attr(title); font-weight: 700; display: block; margin-block: 20px 10px;}

